import { HTMLProps, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { useForm } from 'react-hook-form';

import { Button, Form, Input, NotificationBox } from 'src/components';
import { userHelpers } from 'src/utils/userHelpers';

import {
  useAccountInfo,
  useUpdateAccountInfo,
} from '../../../../api/queries/account';

interface NameForm {
  firstName: string;
  lastName: string;
}

export const EditNameForm = (props: HTMLProps<HTMLDivElement>) => {
  const { user } = useAuth0();
  const accountInfo = useAccountInfo();

  const updateAccountInfo = useUpdateAccountInfo();
  const [isEditable, setIsEditable] = useState(false);

  const form = useForm<NameForm>({
    defaultValues: {
      firstName: user?.given_name ?? '',
      lastName: user?.family_name ?? '',
    },
  });

  const onSubmit = (data: NameForm) => {
    updateAccountInfo.mutate(data, {
      onSuccess: () => {
        setIsEditable(false);
      },
    });
  };

  if (!user) {
    throw new Error('Missing user');
  }

  const identities = accountInfo.data?.identities ?? [];

  const allowedToEdit = userHelpers.canEditName(user, identities);

  return (
    <div {...props}>
      <Form onSubmit={onSubmit} {...form}>
        <div className="flex w-full flex-col justify-between gap-16 lm:flex-row">
          <div className="flex w-full flex-col gap-16 ls:flex-row">
            <Input
              label="First name"
              name="firstName"
              data-cy="first-name"
              className="w-full flex-1 ls:w-fit"
              focusOnIsEditable
              required
              isEditable={isEditable}
              maxLength={150}
            />
            <Input
              className="w-full flex-1 ls:w-fit"
              label="Last name"
              name="lastName"
              data-cy="last-name"
              required
              isEditable={isEditable}
              maxLength={150}
            />
          </div>
          {!isEditable && allowedToEdit && (
            <Button
              data-cy="edit-name-button"
              variant="tertiary"
              className="w-full self-start ls:w-fit"
              size="medium"
              type="button"
              onClick={() => setIsEditable(true)}
            >
              Edit
            </Button>
          )}
        </div>

        {isEditable && (
          <div className="my-16 flex gap-16">
            <Button
              type="button"
              onClick={() => {
                setIsEditable(false);
                form.reset(accountInfo.data);
                updateAccountInfo.reset();
              }}
              variant="secondary"
              data-cy="cancel-edit-name-button"
              size="medium"
            >
              Cancel
            </Button>

            <Button
              type="submit"
              disabled={updateAccountInfo.isPending}
              loading={updateAccountInfo.isPending}
              data-cy="save-name-button"
              size="medium"
              variant="primary"
            >
              Save
            </Button>
          </div>
        )}

        {updateAccountInfo.isError && (
          <NotificationBox
            variant="error"
            title="Something went wrong"
            data-cy="edit-name-error"
          >
            <Typography variant="body-md-regular">
              Please try again later or contact support if the problem persists.
            </Typography>
          </NotificationBox>
        )}
      </Form>
    </div>
  );
};
