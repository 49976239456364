import { useActivePaperTablets } from 'src/api/queries';
import { Spinner } from 'src/components';

import { SetupComplete } from '../components/SetupComplete';

export const ConnectOfferSetupComplete = () => {
  const activePaperTablets = useActivePaperTablets();

  if (activePaperTablets.isPending) return <Spinner />;

  return (
    <SetupComplete
      secondDevice={
        !!(activePaperTablets.data && activePaperTablets.data.length > 1)
      }
    />
  );
};
