import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { queryKeys } from 'src/api/queries';
import {
  useEnterpriseCreateEnrollment,
  useEnterpriseEnrollmentStatus,
} from 'src/api/queries/enterpriseEnrollment';
import {
  Button,
  Divider,
  Form,
  Input,
  NotificationBox,
  Sheet,
} from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { capitalize } from 'src/utils/capitalize';
import { URLS } from 'src/utils/urls/urls';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';
import { getEmailInfo } from '../utils/getEmailDomain';

interface FormData {
  name: string;
}

const routeApi = getRouteApi(
  '/_auth/_layout/enterprise/enroll/create-organization'
);

export function CreateOrganizationPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const navigate = routeApi.useNavigate();
  const { user } = useAuth0();
  const createEnterpriseEnrollment = useEnterpriseCreateEnrollment();
  const emailInfo = getEmailInfo(user?.email ?? '');
  const exampleName = emailInfo.domain.split('.')[0];
  const form = useForm<FormData>();
  const queryClient = useQueryClient();

  const onSubmit = async (data: FormData) => {
    if (enrollmentStatus.isSuccess && !!enrollmentStatus.data) {
      // Enrollment already initiated
      return navigate({ to: '../verify-domain' });
    }

    createEnterpriseEnrollment.mutate(
      {
        orgName: data.name,
        domain: emailInfo.domain,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.enterpriseEnrollment.status,
          });
          return navigate({ to: '../verify-domain' });
        },
      }
    );
  };

  const orgName = enrollmentStatus.data?.displayName;

  useEffect(() => {
    if (!orgName) return;

    form.reset({
      name: orgName,
    });
  }, [orgName]);

  return (
    <AppPage.Container className="max-w-l2xl">
      <AppPage.Header title="Single sign-on setup" className="max-w-l2xl" />

      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />
        <Sheet className="gap-16">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              Register your organization
            </Typography>
          </div>

          <Typography variant="body-md-regular">
            Your organization&apos;s name will be visible on your dashboard.
          </Typography>

          <Divider />

          <Form {...form} onSubmit={onSubmit} className="flex flex-col gap-24">
            <Input
              name="name"
              label="Organization name"
              placeholder={capitalize(exampleName)}
              required={!enrollmentStatus.data}
              disabled={!!enrollmentStatus.data}
            />

            <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
              <div className="flex flex-1 ls:justify-center lm:justify-start">
                <CancelSetupModal
                  trigger={
                    <Button
                      variant="tertiary-neutral"
                      className="w-full ls:w-fit"
                    >
                      Cancel
                    </Button>
                  }
                />
              </div>

              <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
                <Button
                  as="a"
                  variant="secondary"
                  to={URLS.ENTERPRISE_SETUP}
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  loading={createEnterpriseEnrollment.isPending}
                  disabled={createEnterpriseEnrollment.isPending}
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Continue
                </Button>
              </div>
            </div>
          </Form>

          <NotificationBox error={createEnterpriseEnrollment.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
