import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@remarkable/ark-web';
import { useNavigate } from '@tanstack/react-router';
import toast from 'react-hot-toast';

import {
  useEnterpriseEnrollmentStatus,
  useEnterpriseFinalize,
} from 'src/api/queries/enterpriseEnrollment';
import { Button, Divider, Modal, NotificationBox, Sheet } from 'src/components';
import { AppPage } from 'src/components/AppPage';
import { Link2 } from 'src/components/Link2';
import { RadioButton } from 'src/components/RadioButton';
import { URLS } from 'src/utils/urls/urls';
import { userHelpers } from 'src/utils/userHelpers';

import { CancelSetupModal } from '../components/CancelSetup';
import { EnterpriseSteps } from '../components/EnterpriseSteps';

type FinalizeMethod =
  | 'NoTestingPeriod'
  | 'WithMigrationPeriod'
  | 'WithTestingPeriod';

export function ConfirmSetupPage() {
  const enrollmentStatus = useEnterpriseEnrollmentStatus();
  const finalizeEnrollment = useEnterpriseFinalize();
  const [showMigrationModal, setShowMigrationModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const [finalizeMethod, setFinalizeMethod] =
    useState<FinalizeMethod>('WithTestingPeriod');

  const auth = useAuth0();

  const enableMigrationModal = finalizeMethod === 'WithMigrationPeriod';

  const handleFinalize = () => {
    if (
      typeof enrollmentStatus.data?.connectionId !== 'string' ||
      !enrollmentStatus.data.primaryDomain
    )
      return;

    finalizeEnrollment.mutate(
      {
        domain: enrollmentStatus.data.primaryDomain,
        withDomainForwarding: finalizeMethod === 'NoTestingPeriod',
        withMigration:
          finalizeMethod === 'NoTestingPeriod' ||
          finalizeMethod === 'WithMigrationPeriod',
      },
      {
        async onSuccess() {
          const organization = auth.user
            ? userHelpers.getOrganizationId(auth.user)
            : undefined;

          toast.success('Enterprise enrollment complete!');

          return navigate({
            to: '/login',
            search: {
              prompt: 'none',
              redirect: '/enterprise',
              organization,
            },
          });
        },
      }
    );
  };

  return (
    <AppPage.Container>
      <AppPage.Header title="Single sign-on setup" />
      <AppPage.Content className="max-w-ll">
        <EnterpriseSteps />

        <Sheet className="gap-24">
          <div className="flex items-center justify-between gap-16">
            <Typography as="h2" variant="heading-sm">
              All set
            </Typography>
          </div>

          <Typography variant="body-md-regular">
            You&apos;ve successfully set up single sign-on. The next step is to
            decide if you would like to invite a few users to test the new
            login, or migrate all users to single sign-on right away.
          </Typography>

          <Divider />

          <div className="flex flex-col gap-16">
            <RadioButton
              disabled={enrollmentStatus.data?.enrollment === 'FINALIZED'}
              checked={finalizeMethod === 'WithTestingPeriod'}
              name="finalizeMethod"
              onChange={() => setFinalizeMethod('WithTestingPeriod')}
            >
              <Typography variant="body-xl-regular">
                Invite a few users for testing
              </Typography>
              <Typography variant="body-sm-regular">
                Add a small number of users to your identity provider and invite
                them to try the new single sign-on login. Once you&apos;re sure
                it&apos;s working well, you can begin migrating all users.
              </Typography>
            </RadioButton>
            <RadioButton
              disabled={enrollmentStatus.data?.enrollment === 'FINALIZED'}
              checked={finalizeMethod === 'WithMigrationPeriod'}
              name="finalizeMethod"
              onChange={() => setFinalizeMethod('WithMigrationPeriod')}
            >
              <Typography variant="body-xl-regular">
                Migrate all users to single sign-on now
              </Typography>
              <Typography variant="body-sm-regular">
                All users with your company&apos;s domain email will be asked to
                switch to single sign-on. This is a temporary period, allowing
                users to gradually transition to their new login method. If
                you&apos;re not ready to migrate all users yet, you can do this
                later.
              </Typography>
            </RadioButton>
          </div>

          <div className="flex w-full flex-col-reverse flex-wrap-reverse gap-24 lm:flex-row">
            <div className="flex flex-1 ls:justify-center lm:justify-start">
              <CancelSetupModal
                trigger={
                  <Button
                    variant="tertiary-neutral"
                    className="w-full ls:w-fit"
                  >
                    Cancel
                  </Button>
                }
              />
            </div>

            <div className="flex flex-col-reverse flex-wrap-reverse gap-24 ls:flex-row">
              <Button
                as="a"
                variant="secondary"
                to={URLS.ENTERPRISE_VERIFY_SAML}
                className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
              >
                Previous
              </Button>

              {enrollmentStatus.data?.enrollment === 'FINALIZED' ? (
                <Link2
                  as="button"
                  to={URLS.ENTERPRISE}
                  variant="primary"
                  className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                >
                  Go to Overview
                </Link2>
              ) : (
                <Modal.Root
                  open={showMigrationModal}
                  onOpenChange={(open) => {
                    if (!enableMigrationModal) {
                      setShowMigrationModal(false);
                      return;
                    }

                    finalizeEnrollment.reset();

                    setShowMigrationModal(open);
                  }}
                >
                  <Modal.Trigger asChild>
                    <Button
                      variant="primary"
                      disabled={finalizeEnrollment.isPending}
                      loading={finalizeEnrollment.isPending}
                      className="w-full ls:w-fit ls:flex-1 lm:flex-initial"
                      onClick={() => {
                        if (
                          typeof enrollmentStatus.data?.connectionId !==
                          'string'
                        )
                          return;

                        if (!enableMigrationModal) {
                          handleFinalize();
                        }
                      }}
                    >
                      Complete
                    </Button>
                  </Modal.Trigger>

                  <Modal.Content className="flex max-w-3xl flex-col gap-24">
                    <Typography variant="heading-lg" as="h2">
                      Are you sure you want to continue?
                    </Typography>
                    <Typography variant="body-md-regular">
                      Users with email addresses under your domain, whether
                      added to your identity provider (IdP) or not, will be
                      flagged for migration and notified by email. They can
                      continue accessing their accounts but will see a banner
                      prompting them to switch to the new SSO system.
                    </Typography>

                    <NotificationBox
                      variant="warning"
                      title="Make sure users are added to the IdP before starting the migration phase"
                    >
                      If you have not added users to your IdP, they will not be
                      able complete the migration, as they will be unable to
                      authenticate through SSO.
                    </NotificationBox>

                    <div className="flex justify-center gap-24">
                      <Modal.Close asChild>
                        <Button variant="secondary">Cancel</Button>
                      </Modal.Close>
                      <Button
                        variant="primary"
                        disabled={finalizeEnrollment.isPending}
                        loading={finalizeEnrollment.isPending}
                        onClick={() => {
                          handleFinalize();
                        }}
                      >
                        Continue
                      </Button>
                    </div>

                    <NotificationBox error={finalizeEnrollment.error} />
                  </Modal.Content>
                </Modal.Root>
              )}
            </div>
          </div>

          <NotificationBox error={finalizeEnrollment.error} />
        </Sheet>
      </AppPage.Content>
    </AppPage.Container>
  );
}
