import { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import { MenuButton, Typography } from '@remarkable/ark-web';
import { CaretDown, CheckCircle, User, Warning } from 'phosphor-react';
import { Toaster } from 'react-hot-toast';

import { ButtonClicked } from 'src/ampli';
import { tracker } from 'src/analytics/tracker';
import { ComponentLocations } from 'src/analytics/trackingTypes';
import { Button, Divider } from 'src/components';
import { Link2 } from 'src/components/Link2';
import { RemarkableLogo } from 'src/components/RemarkableLogo';
import { useSideMenuState } from 'src/hooks/useSideMenuState';
import { userHelpers } from 'src/utils/userHelpers';

import { HeaderNotification } from './HeaderNotification';

interface HeaderProps {
  variant: 'full' | 'minimal';
}

export const Header = (props: HeaderProps) => {
  const { variant } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const auth = useAuth0();
  const sideMenu = useSideMenuState();
  const isRestrictedSamlUser = auth.user
    ? userHelpers.isAccountRestrictedToAccountLinking(auth.user)
    : false;

  return (
    <>
      <div
        data-cy="header"
        className="fixed left-0 top-0 z-40 flex h-56 w-full animate-fade-in-down items-center bg-neutral-light-4 px-20 text-black smd:h-64"
      >
        {variant === 'full' &&
          auth.isAuthenticated &&
          !isRestrictedSamlUser && (
            <MenuButton
              menuDrawerId={'menu-button'}
              className="float-left p-4"
              menuDrawerOpen={sideMenu.isOpen} // sets tabindex correctly to allow focus
              onClick={() => {
                tracker.trackEvent(
                  new ButtonClicked({
                    component_location: ComponentLocations.HEADER.MAIN,
                    text: 'Open side menu',
                    action: 'open side menu',
                  })
                );
                sideMenu.open();
              }}
              id="open-nav-menu-button"
              data-cy="open-nav-menu"
              aria-label="Open menu"
            />
          )}

        {variant === 'full' ? (
          <Link2
            to="/"
            className="absolute left-1/2 flex h-full -translate-x-1/2 items-center"
          >
            <RemarkableLogo color="black" />
          </Link2>
        ) : (
          <div className="absolute left-1/2 flex h-full -translate-x-1/2 items-center">
            <RemarkableLogo color="black" />
          </div>
        )}

        {variant === 'full' && (
          <div className="absolute right-20 flex h-full items-center gap-24">
            <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="tertiary-neutral"
                  className="p-0 lm:p-16"
                  analytics={{
                    action: 'open header user menu',
                    location: ComponentLocations.HEADER.USER_MENU,
                    text: 'user name',
                  }}
                  data-cy="user-menu"
                  aria-label="Open user menu"
                >
                  <User size={32} />
                  <Typography
                    variant="interface-md-medium"
                    className="hidden max-w-112 truncate lm:block ll:max-w-[200px] lxl:max-w-[300px]"
                  >
                    {/* For users that havent set name, name will be their email */}
                    {auth.user?.name}
                  </Typography>
                  <CaretDown size={24} />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                sideOffset={20}
                side="bottom"
                align="end"
                className="popover-with-arrow z-20 rounded bg-white shadow-sheet"
              >
                <div className="space-y-16 bg-neutral-light-2 p-16">
                  <Typography variant="body-sm">{auth.user?.email}</Typography>
                  <Divider />
                  {!isRestrictedSamlUser && (
                    <Link2
                      to="/account"
                      className="block"
                      onClick={() => setPopoverOpen(false)}
                      componentLocation={ComponentLocations.HEADER.USER_MENU}
                    >
                      Account
                    </Link2>
                  )}

                  <Link2
                    to="/logout"
                    className="block"
                    componentLocation={ComponentLocations.HEADER.USER_MENU}
                    data-cy="nav-log-out"
                  >
                    Log out
                  </Link2>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        )}
      </div>
      <div className="h-56 smd:h-64" />
      {variant === 'full' && (
        <div className="w-full">
          <HeaderNotification />
        </div>
      )}
      <Toaster
        position="top-right"
        containerStyle={{ top: 72, zIndex: 49 }}
        toastOptions={{
          style: {
            borderRadius: '4px',
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '12px',
            paddingBottom: '12px',
            alignItems: 'center',
          },
          error: {
            icon: (
              <Warning size={24} className="shrink-0 text-feedback-red-500" />
            ),
          },
          success: {
            icon: (
              <CheckCircle
                size={24}
                className="shrink-0 text-feedback-green-500"
              />
            ),
          },
        }}
      />
    </>
  );
};
