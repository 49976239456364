import { ToOptions } from '@tanstack/react-router';

export enum AUTH_URLS {
  MYFILES = '/myfiles',
  MYFILES_TRASH = '/myfiles/trash',
  HOME = '/',
  DEVICE = '/device',
  DEVICE_BROWSER = '/device/browser',
  DEVICE_MOBILE = '/device/mobile',
  DEVICE_DESKTOP = '/device/desktop',
  DEVICE_REMARKABLE = '/device/remarkable',
  DEVICE_BROWSER_CONNECT = '/device/browser/connect',
  DEVICE_MOBILE_CONNECT = '/device/mobile/connect',
  DEVICE_DESKTOP_CONNECT = '/device/desktop/connect',
  DEVICE_REMARKABLE_CONNECT = '/device/remarkable/connect',
  /** Handled by the device app */
  PAIR = '/pair',
  /** Handled by the device app */
  PAIR_APP = '/pair/app',
  SETTINGS = '/settings',
  ACCOUNT = '/account',
  ACCOUNT_DELETE_LANDING = '/account/delete/landing',
  ACCOUNT_DELETE_LANDING_APP = '/account/delete/landing/app',
  ACCOUNT_DELETE_CONFIRMATION = '/account/delete/confirmation',
  ACCOUNT_DELETE_CONFIRMATION_APP = '/account/delete/confirmation/app',
  ACCOUNT_MIGRATE = '/account/migrate',
  ACCOUNT_MIGRATE_SUCCESS = '/account/migrate/success',

  // ==================== STORE =======================
  STORE = '/store',

  STORE_CONNECT = '/store/connect',
  STORE_CONNECT_LANDING = '/store/connect',
  STORE_CONNECT_CHECKOUT = '/store/connect/checkout',
  STORE_CONNECT_CONFIRMATION = '/store/connect/confirmation',

  STORE_DM_OFFER = '/store/dm-offer',
  STORE_DM_OFFER_LANDING = '/store/dm-offer',
  STORE_DM_OFFER_CHECKOUT = '/store/dm-offer/checkout',
  STORE_DM_OFFER_CONFIRMATION = '/store/dm-offer/confirmation',
  STORE_DM_OFFER_DOWNLOAD_APPS = '/store/dm-offer/download-apps',
  STORE_DM_OFFER_SETUP_COMPLETE = '/store/dm-offer/setup-complete',

  STORE_CONNECT_OFFER = '/store/connect-offer',
  STORE_CONNECT_OFFER_LANDING = '/store/connect-offer',
  STORE_CONNECT_OFFER_CHECKOUT = '/store/connect-offer/checkout',
  STORE_CONNECT_OFFER_CONFIRMATION = '/store/connect-offer/confirmation',
  STORE_CONNECT_OFFER_DOWNLOAD_APPS = '/store/connect-offer/download-apps',
  STORE_CONNECT_OFFER_SETUP_COMPLETE = '/store/connect-offer/setup-complete',

  STORE_METHODS_OFFER = '/store/methods-offer',
  STORE_METHODS_OFFER_LANDING = '/store/methods-offer',
  STORE_METHODS_OFFER_CHECKOUT = '/store/methods-offer/checkout',
  STORE_METHODS_OFFER_CONFIRMATION = '/store/methods-offer/confirmation',
  STORE_METHODS_OFFER_DOWNLOAD_APPS = '/store/methods-offer/download-apps',
  STORE_METHODS_OFFER_SETUP_COMPLETE = '/store/methods-offer/setup-complete',

  // ================ SUBSCRIPTIONS ===================
  SUBSCRIPTION = '/subscriptions',
  SUBSCRIPTION_MEMBERS = '/subscriptions/members',
  SUBSCRIPTION_PLAN = '/subscriptions/plan',
  SUBSCRIPTION_CANCEL = '/subscriptions/cancel',
  SUBSCRIPTION_PAYMENT = '/subscriptions/payment',
  SUBSCRIPTION_INVITE = '/subscriptions/invite',
  INVITATION_CONNECT_FOR_BUSINESS_MEMBER = '/invitation/seat',
  INTEGRATIONS = '/integrations',
  VERIFY_NEW_EMAIL = '/verify-new-email',
  ACCEPT_TERMS = '/accept-terms',
  FREE_ACCESS_TERMS = '/free-access-terms',
  USER_CONSENT = '/data-access-consent',
  ENTERPRISE = '/enterprise',
  ENTERPRISE_ENROLL = '/enterprise/enroll',
  ENTERPRISE_ACCOUNT_VALUE_PROP = '/enterprise/enroll/account-value-prop',
  ENTERPRISE_SETUP = '/enterprise/enroll/setup',
  ENTERPRISE_CREATE_ORGANIZATION = '/enterprise/enroll/create-organization',
  ENTERPRISE_VERIFY_DOMAIN = '/enterprise/enroll/verify-domain',
  ENTERPRISE_SETUP_SAML = '/enterprise/enroll/setup-saml',
  ENTERPRISE_VERIFY_SAML = '/enterprise/enroll/verify-saml',
  ENTERPRISE_CONFIRM_SETUP = '/enterprise/enroll/confirm-setup',
}

export enum STANDALONE_URLS {
  /** Email verification landing page */
  VERIFIED = '/verified',
}

export enum OPEN_URLS {
  LANDING_PAGE_DEVICE_SETUP = '/landing-page/device-setup',
  DOWNLOAD_MOBILE_APP_REDIRECT = '/downloads/mobile-app',
}

export const EXTERNAL_URLS = {
  CANCELLED_SUBSCRIPTION_SURVEY: 'https://www.surveymonkey.com/r/XBJ7ZPD',
  RETAIL_CUSTOMER_SURVEY: 'https://www.surveymonkey.com/r/YMR6JC2',
  CONNECT_FOR_BUSINESS_BETA_FEEDBACK: 'https://www.surveymonkey.com/r/Y2DSZZR',
} as const;

export const RM_COM_URLS = {
  RM_COM: `https://remarkable.com`,
  RM_COM_STORE: `https://remarkable.com/store`,
  RM_COM_STORE_CONNECT: `https://remarkable.com/store/connect`,
  RM_COM_STORE_SUBSCRIPTION: `https://remarkable.com/store/subscription`,
  RM_COM_ABOUT: `https://remarkable.com/about`,
  RM_COM_BLOG: `https://remarkable.com/blog`,
  RM_COM_CONTACT: `https://remarkable.com/contact`,
  RM_COM_USING_REMARKABLE: `https://remarkable.com/using-remarkable`,
  RM_COM_USING_REMARKABLE_LEARN_THE_BASICS: `https://remarkable.com/using-remarkable/learn-the-basics`,
  RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_WRITING_TOOLS: `https://remarkable.com/using-remarkable/learn-the-basics/how-to-use-writing-tools-on-remarkable`,
  RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_GESTURES: `https://remarkable.com/using-remarkable/learn-the-basics/how-to-navigate-your-remarkable-with-gestures`,
  RM_COM_USING_REMARKABLE_LEARN_THE_BASICS_LEVEL_UP_WRITING: `https://remarkable.com/using-remarkable/paper-like-writing-and-reading/5-ways-to-level-up-your-writing-game-with-remarkable`,
  RM_COM_BIG_CHANGES_COMING_TO_REMARKABLE_ECOSYSTEM: `https://remarkable.com/blog/big-changes-are-coming-to-the-remarkable-ecosystem`,
  RM_COM_INTRODUCING_TYPE_FOLIO: `https://remarkable.com/blog/introducing-type-folio-a-new-way-to-work-on-remarkable`,
  RM_COM_SOFTWARE_UPDATE_3_8: `https://remarkable.com/blog/software-update-3-8-straight-lines-and-checkboxes`,
  RM_COM_CONNECT_FOR_BUSINESS_GET_STARTED:
    'https://remarkable.com/clp/connect-for-business-get-started',
  RM_COM_PERSONALIZE_YOUR_NOTES_WITH_COVER_PAGES:
    'https://remarkable.com/using-remarkable/learn-the-basics/personalize-your-notes-with-inspiring-cover-pages',
  RM_COM_ORGANIZE_YOUR_FILES:
    'https://remarkable.com/using-remarkable/get-organized/level-up-your-organizing-skills-with-folders-and-tags',
  RM_COM_GET_STARTED_WITH_REMARKABLE_PAPER_PRO:
    'https://remarkable.com/using-remarkable/learn-the-basics/get-started-with-remarkable-paper-pro',
  RM_COM_HOW_TO_GESTURES:
    'https://remarkable.com/using-remarkable/learn-the-basics/how-to-use-gestures-on-remarkable',
  RM_COM_HOW_TO_WRITING_TOOLS:
    'https://remarkable.com/using-remarkable/learn-the-basics/how-to-use-writing-tools-on-remarkable-paper-pro',
  RM_COM_HOW_TO_APPS:
    'https://remarkable.com/using-remarkable/learn-the-basics/how-to-use-the-remarkable-desktop-and-mobile-apps',
  RM_COM_HOW_TO_COMBINE_HW_AND_TYPED:
    'https://remarkable.com/using-remarkable/learn-the-basics/how-to-combine-handwriting-and-typing-on-remarkable',
  RM_COM_HOW_TO_TAGS:
    'https://remarkable.com/using-remarkable/learn-the-basics/how-to-use-tags-on-remarkable',
  RM_COM_HOW_TO_ORGANIZE:
    'https://remarkable.com/using-remarkable/get-organized/3-pro-tips-for-organizing-your-paper-tablet',
  RM_COM_HOW_TO_COVER_PAGE:
    'https://remarkable.com/using-remarkable/get-organized/personalize-your-notes-with-inspiring-cover-pages',
  RM_COM_HOW_TO_FOLDER_AND_TAGS:
    'https://remarkable.com/using-remarkable/get-organized/level-up-your-organizing-skills-with-folders-and-tags',
} as const;

const SCREENSHARE_URLS = {
  SCREENSHARE_HOME: '/screenshare',
};

export const DEV_URLS = {
  STRIPE_TEST_CLOCK_DOCS: 'https://stripe.com/docs/billing/testing/test-clocks',
} as const;

export const URLS = {
  ...AUTH_URLS,
  ...STANDALONE_URLS,
  ...OPEN_URLS,
  ...EXTERNAL_URLS,
  ...RM_COM_URLS,
  ...SCREENSHARE_URLS,
};

export const isOpenUrl = (url: string): boolean => {
  return Object.values(OPEN_URLS).some((openUrl) => url.startsWith(openUrl));
};

export const WHITELISTED_ACCOUNT_LINKING_PAGES_FOR_RESTRICTED_ACCOUNTS: ToOptions['to'][] =
  ['/login', '/logout', '/account/migrate/saml', '/enterprise'];
